import React from 'react';
import { CPJProductsLogo } from '../../assets/svg/cpj-products-logo';
import './footer.scss';

export function Footer() {
  return (
    <React.Fragment>
      <footer className="footer has-background-white-ter" >
        <div className="content has-text-centered">
          <p>© CPJ Products 2020</p>
          <br/>
          <CPJProductsLogo
              width="4em"
              height="auto"
              color="#3852e2" // TODO this shouldn't be hardcoded
              viewBox="0 0 132 207" />
        </div>
      </footer>
    </React.Fragment>

  );
}