import {useEffect, useState} from 'react';

export const IS_MOBILE = "is-mobile";
export const IS_TABLET = "is-tablet";

export const mediaConstants = {
  IS_MOBILE: IS_MOBILE,
  IS_TABLET: IS_TABLET
}

export function useMedia() {
  const queries = ["(max-width: 767px)", "(min-width: 767px)"];
  const values = [IS_MOBILE, IS_TABLET];
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map(q => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getScreenSize = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return typeof values[index] !== 'undefined' ? values[index] : IS_MOBILE;
  };

  // State and setter for matched value
  const [screenSize, setScreenSize] = useState(getScreenSize);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setScreenSize(getScreenSize);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach(mql => mql.addListener(handler));
      // Remove listeners on cleanup
      return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    } // TODO supply appropriate dependencies
  );

  return screenSize;
}