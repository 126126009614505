import React, { FunctionComponent } from 'react';
import './description.scss';


export const Description: FunctionComponent<any> = (props) => {
  return (
    <div className="description is-size-6-mobile is-size-4-tablet is-size-5-desktop">
      <ul>
        <li><span className="blue">Cool & Easy</span> <span className="red">prevents</span> and <span className="red">stops</span> razor bumps (<span className="red">Pseudofolliculitis Barbae</span>) from shaving</li>
        <li><span className="blue">Cool & Easy</span> stops skin <span className="red">irritation</span> from shaving</li>
        <li><span className="blue">Cool & Easy</span> stops <span className="red">bleeding</span> from <span className="red">nicks</span> and <span className="red">cuts</span> from shaving</li>
        <li><span className="blue">Cool & Easy</span> heals <span className="red">nicks</span> and <span className="red">cuts</span> from shaving</li>
        <li><span className="blue">Cool & Easy</span> removes <span className="red">dead</span> skin after shaving</li>
        <li><span className="blue">Cool & Easy</span> leaves the skin <span className="red">soft</span>, <span className="red">smooth</span>, and <span className="red">moisturized</span> after shaving</li>
        <li><span className="blue">Cool & Easy</span> is <span className="red">strong</span> enough for a <span className="red">man</span> and <span className="red">gentle</span> enough for a <span className="red">woman</span></li>
      </ul>
    </div>
  )
}