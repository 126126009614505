import React, { FunctionComponent } from 'react';

import razorBumpStopper from "../../assets/images/razor-bump-stopper.png";
import './product.scss';
import { BuyOnAmazonBtn } from '../generic/buy-on-amazon/buy-on-amazon';
import { useMedia, mediaConstants } from '../../hooks/hooks';
import { SectionHeader } from '../generic/section-header/section-header';
import { TabbedContainer } from '../generic/tab-menu/tabbed-container';
import { ContentBlock } from '../generic/content-block/content-block';
import { Description } from '../product-specific/razor-bump-stopper/description/description';

interface ProductProps {
  title: string
  subtitle: string
  tagline?: string
  price: number
  ingredients: string
  usage: string
}

export const Product: FunctionComponent<ProductProps> = (props: ProductProps) => {
  const { title, subtitle, price, ingredients, tagline, usage } = props;
  // Manage screenSize
  const screenSize = useMedia();

  let ingredientsHeader = "Ingredients";
  let usageHeader = "Usage";

  let pricePrefix = "Price: $";
  let priceString = () => (
    <p className="is-size-6-mobile is-size-5-tablet has-text-weight-semibold">
      {pricePrefix + price.toFixed(2)}
    </p>
  );

  let isMobile = screenSize === mediaConstants.IS_MOBILE;

  return (
    <div id="product-container" className="container is-flex">
      {isMobile &&
        <React.Fragment>
          <h3 className="title is-size-4-mobile is-size-1-tablet">{title}</h3>
          {subtitle &&
            <h5 className="subtitle is-size-6-mobile is-size-5-tablet">
              {subtitle}{tagline && <h6 className="tagline is-size-6-mobile is-size-5-tablet">{tagline}</h6>}
            </h5>}
          <ProductImage imgLink={razorBumpStopper} />
          {priceString()}
          <BuyOnAmazonBtn />
          {/* <ContentBlock text={description}>{<Description />}</ContentBlock> */}
          <Description />
        </React.Fragment>}
      {!isMobile &&
        <div className="columns is-mobile">
          <div className="column">
            <ProductImage imgLink={razorBumpStopper} />
          </div>
          <div className="column">
            <h3 className="title is-size-5-mobile is-size-2-tablet">{title}</h3>
            {subtitle && 
              <h5 className="subtitle is-size-6-mobile is-size-3-tablet has-text-grey">
                {subtitle}{tagline && <h6 className="tagline is-italic is-size-6-mobile is-size-5-tablet">{tagline}</h6>}
              </h5>}
            {priceString()}
            <BuyOnAmazonBtn />
            {/* <ContentBlock text={description}>{<Description />}</ContentBlock> */}
            <Description />
          </div>
        </div>}
      <br />
      {isMobile &&
        <TabbedContainer
          className="product-details"
          menuDict={{
            [ingredientsHeader]: ingredients,
            [usageHeader]: usage
          }} />}
      {!isMobile &&
        <React.Fragment>
          <SectionHeader title={ingredientsHeader} />
          <p className="is-capitalized is-size-6-mobile is-size-4-tablet is-size-5-desktop">
            {ingredients}
          </p>
          <br />
          <SectionHeader title={usageHeader} />
          <ContentBlock>{usage}</ContentBlock>
        </React.Fragment>
      }
    </div>
  );
}

type ProductImageProps = {
  imgLink: string;
}
const ProductImage: FunctionComponent<ProductImageProps> = (props) => {
  return (
    <figure id="product-image" className="image is-square">
      <img src={props.imgLink} alt="Razor Bump Prevention" />
    </figure>
  )
};
