import React, { FunctionComponent } from 'react';


export type ContentBlockProps = {
  text?: string
  // children?: React.ReactNode
}
export const ContentBlock: FunctionComponent<ContentBlockProps> = (props) => {
  return (
    <div className="content has-text-left-tablet">
      <p className="is-size-6-mobile is-size-5-tablet">{props.children}</p>
    </div>
  )
}
