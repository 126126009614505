import React from 'react';
// import { Link } from 'react-router-dom';
import { CPJProductsLogo } from '../../assets/svg/cpj-products-logo';

import './nav-bar.scss';


export function NavBar(props: any) {
  let { companyName } = props;
  let homepageLink = process.env.REACT_APP_HOME_PAGE_URL;

  return (
    <nav className="navbar is-fixed-top has-background-primary" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <CPJProductsLogo
          width="3em"
          height="auto"
          color="#FFFFFF" // TODO this shouldn't be hardcoded
          viewBox="0 0 132 207" />
        <a className="navbar-item has-text-white is-size-3-tablet" href={homepageLink}>
          {companyName}
        </a>
      </div>
    </nav>
  );
}
