import React from 'react';
import './home.scss';
import { Product } from '../product/product';
import { useMedia, mediaConstants } from '../../hooks/hooks';
import { ContentBlock } from '../generic/content-block/content-block';


export function Home(props: any) {
  // Manage screenSize
  const screenSize = useMedia();

  let title = "Cool & Easy Razor Bump Prevention";
  let subtitle = "Witch Hazel Solution";
  let price = 14.99;
  // let description = "Cool & Easy prevents razor bumps pseudofolliculitis barbae from shaving. It also stops skin irritation and bleeding of the skin from shaving.";
  let tagline = "A non-alcoholic formula"
  let ingredients = "Witch Hazel, Filtered Water, Citric Acid (Lemon Extract), Ammonium Alum, Vegetable Glycerin, FD&C Blue no. 1";
  let usage = "Apply product liberally to cotton swab or tissue. Swab and/or wipe shaved or irritated area of skin. Apply daily to enhance conditioning of skin.";
  // let introHeader = "Introduction";
  let introText = "CPJ Products is a hair and skin care products manufacturer. We make high quality products at affordable prices. Through our line of products you can improve on the health and quality of your hair and skin care."

  let isMobile = screenSize === mediaConstants.IS_MOBILE;
  let sectionClassName = "section" + (isMobile ? "" : " is-large");

  return (
    <React.Fragment>
      <section className={sectionClassName}>
        <div className="container">
          <ContentBlock>{introText}</ContentBlock>
        </div>
      </section>
      <section className={sectionClassName}>
        <Product
          title={title}
          subtitle={subtitle}
          tagline={tagline}
          price={price}
          ingredients={ingredients}
          usage={usage} />
      </section>
    </React.Fragment>
  );
}