import React from 'react';


export type SectionHeaderProps = { // TODO add className as prop
  title: string;
  subtitle?: string;
}
export function SectionHeader (props: SectionHeaderProps) {
  const { title, subtitle } = props;
  return (
    <React.Fragment>
      <h3 className="title is-size-4-mobile is-size-1-tablet">{title}</h3>
      {subtitle && <h5 className="subtitle is-size-6-mobile is-size-5-tablet">{subtitle}</h5>}
    </React.Fragment>
  )
};