import React, { useState } from 'react';

import './tabbed-container.scss';

/**
 * Expects menuDict in props.
 * Must be a dict with the tab names as keys,
 * and valid react components as values
 */
export function TabbedContainer(props: any) {
  const [selectedTab, setSelectedTab] = useState(0);
  let { className, menuDict } = props;
  let tabNames = Object.keys(menuDict);

  return (
    <div className={className}>
      <div className={`buttons has-addons is-centered`}>
        {tabNames.map((tabName: string, index: number) => {
          let initialClassName = "button tabbed-container-header";
          let adjustedClassName = (selectedTab === index)
            ? initialClassName + " is-primary is-selected"
            : initialClassName;
          return (
            <button
              key={index}
              className={adjustedClassName}
              onClick={() => {
                setSelectedTab(index)
              }} >
              <span>{tabName}</span>
            </button>
          );
        })}
      </div>
      <div className="selected-container">
        {menuDict[tabNames[selectedTab]]}
      </div>
    </div>
  );
}