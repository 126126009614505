import React from 'react';
// import { useMedia, IS_MOBILE } from '../../../hooks/hooks';

export function BuyOnAmazonBtn() {
  let buyButtonText = "Buy on Amazon";
  // let screenSize = useMedia();
  // let isMobile = screenSize === IS_MOBILE;

  let amazonLink = process.env.REACT_APP_AMAZON_URL || "";
  let className = `button buy-button is-fullwidth level-item`;
  
  return (
    <a id="buy-button" rel="noopener noreferrer" target="_blank" className={className} href={amazonLink} onClick={() => console.log("click")}>
      {/* <span className="icon is-medium">
        <i className="fab fa-amazon"></i>
      </span> */}
      <span>
        {buyButtonText}
      </span>
    </a>
  );
}