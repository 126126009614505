import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';
const history = createBrowserHistory();

ReactDOM.render(
    <App pathname={history.location.pathname} />, document.getElementById('root')
  // <Router>
  //   <App pathname={history.location.pathname} />
  // </Router>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
