import React, { Fragment } from 'react';
// import { Route } from 'react-router-dom';

// internal components
import { Home } from './components/home/home';
import { NavBar } from './components/nav-bar/nav-bar';
import { Footer } from './components/footer/footer';

// services


// css
import './App.scss';

export interface AppProps {
  pathname: string
}

export default function App(props: AppProps) {

  // Add routes that will be displayed in NavBar component
  // const navRoutes = [
  //   { path: "/", linkName: "Home", exact: true, component: Home }
  // ]

  // let navProps = {
  //   routes: navRoutes,
  // };
  // const routes = navRoutes;

  let companyName = "CPJ Products";
  return (
    <Fragment>
      <NavBar companyName={companyName} />
      {/* <div id="main-content">
        {routes.map((route, idx) => {
          return (<Route key={`route-${idx}`} path={route.path} exact={route.exact} component={route.component} />);
        })}
      </div> */}
      <div id="main-content">
        <Home />
      </div>
      <Footer />
      {/* Photo by Supply on Unsplash */}
      {/* TODO use link below instead of text above */}
      {/* <Link className="" style="background-color:black;color:white;text-decoration:none;padding:4px 6px;font-family:-apple-system, BlinkMacSystemFont, &quot;San Francisco&quot;, &quot;Helvetica Neue&quot;, Helvetica, Ubuntu, Roboto, Noto, &quot;Segoe UI&quot;, Arial, sans-serif;font-size:12px;font-weight:bold;line-height:1.2;display:inline-block;border-radius:3px" href="https://unsplash.com/@supply?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Supply"><span style="display:inline-block;padding:2px 3px"><svg xmlns="http://www.w3.org/2000/svg" style="height:12px;width:auto;position:relative;vertical-align:middle;top:-2px;fill:white" viewBox="0 0 32 32"><title>unsplash-logo</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg></span><span style="display:inline-block;padding:2px 3px">Supply</span></Link> */}
    </Fragment>
  );
}
